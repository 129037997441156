import { localStorageSave, localStorageSaveObject } from './localStorageUtils';

export function parseJwt(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function isJwtExpired(jwt: any): boolean {
  const now: number = Date.now() / 1000;
  const { exp } = jwt;
  if (exp < now) {
    return true;
  }
  return false;
}

export function authSave(user: IUser) {
  localStorage.removeItem('token');
  if (user && user.token !== undefined) {
    localStorageSave('token', user.token);
    const saveData = { ...user };
    localStorageSaveObject('user', saveData);
  }
}

export function authRemove() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

// 기존 로그인이 있을 경우 처리
export function authLoad() {
  const token: string | null = localStorage.getItem('token');
  if (token === null || token === 'undefined') {
    return null;
  }

  const jwt = parseJwt(token);

  if (isJwtExpired(jwt)) {
    localStorage.clear();
    return null;
  }

  return jwt;
}
