import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';

const CenterLayout = () => {
  return (
    <Row style={{ height: '100vh' }}>
      <Col span={12} offset={6} style={{ paddingTop: '10vh' }}>
        <Title> Playpot Administrator</Title>
        <Outlet />
      </Col>
    </Row>
  );
};

export default CenterLayout;
