import React from 'react';
import { Navigate } from 'react-router-dom';
import { localStorageLoad } from '../lib/localStorageUtils';

const RootView = (): React.ReactElement | null => {
  const token = localStorageLoad('token');
  return <Navigate to={token ? '/admin/dashboard' : '/auth/login'} />;
};

export default RootView;
