import {
  // ApartmentOutlined,
  CloudUploadOutlined,
  DashboardFilled,
  ProfileFilled,
  TeamOutlined,
  TwitterOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';

export interface SideMenuConfig {
  title: string;
  type: 'menu' | 'subMenu';
  roles?: AdminRole[];
  icon?: React.ReactNode;
  link?: string;
  children?: SideMenuConfig[];
  breadCrumb?: any;
}

const menuConfig = [
  {
    title: 'Dashboard',
    type: 'menu',
    icon: <DashboardFilled />,
    link: '/admin/dashboard',
    roles: ['root', 'manager'],
    breadCrumb: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      },
      {
        path: '/admin/dashboard',
        breadcrumbName: 'Dashboard',
      },
    ],
  },
  // {
  //   title: 'WSL',
  //   type: 'menu',
  //   icon: <ApartmentOutlined />,
  //   link: '/admin/wsl',
  //   roles: ['root', 'manager'],
  //   breadCrumb: [
  //     {
  //       path: '/admin',
  //       breadcrumbName: 'Admin',
  //     },
  //     {
  //       path: '/admin/wsl',
  //       breadcrumbName: 'Wsl',
  //     },
  //   ],
  // },
  {
    title: 'Banner',
    type: 'menu',
    icon: <CloudUploadOutlined />,
    link: '/admin/banner',
    roles: ['root', 'manager'],
    breadCrumb: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      },
      {
        path: '/admin/banner',
        breadcrumbName: 'Banner',
      },
    ],
  },
  {
    title: 'User',
    type: 'menu',
    icon: <UserOutlined />,
    link: '/admin/user',
    roles: ['root', 'manager'],
    breadCrumb: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      },
      {
        path: '/admin/user',
        breadcrumbName: 'User',
      },
    ],
  },
  {
    title: 'Team',
    type: 'menu',
    icon: <TeamOutlined />,
    link: '/admin/team',
    roles: ['root', 'manager'],
    breadCrumb: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      },
      {
        path: '/admin/team',
        breadcrumbName: 'team',
      },
    ],
  },
  {
    title: 'Competition',
    type: 'subMenu',
    icon: <TwitterOutlined />,
    link: '/admin/competition',
    roles: ['root', 'manager'],
    children: [
      {
        title: 'Competition',
        type: 'menu',
        link: '/admin/competition/list',
        breadCrumb: [
          {
            path: '/admin',
            breadcrumbName: 'Admin',
          },
          {
            path: '/admin/competition',
            breadcrumbName: 'competition',
          },
          {
            path: '/admin/competition/list',
            breadcrumbName: 'list',
          },
        ],
      },
      {
        title: 'Config',
        type: 'menu',
        link: '/admin/competition/config',
        breadCrumb: [
          {
            path: '/admin',
            breadcrumbName: 'Admin',
          },
          {
            path: '/admin/competition',
            breadcrumbName: 'competition',
          },
          {
            path: '/admin/competition/config',
            breadcrumbName: 'config',
          },
        ],
      },
    ],
  },
  {
    title: 'Admins manage',
    type: 'menu',
    icon: <ProfileFilled />,
    link: '/admin/admin',
    roles: ['root'],
    breadCrumb: [
      {
        path: '/admin',
        breadcrumbName: 'Admin',
      },
      {
        path: '/admin/admin',
        breadcrumbName: 'Admin manage',
      },
    ],
  },
] as SideMenuConfig[];

export function currentMenu(pathname: string): SideMenuConfig | undefined {
  for (let i = 0; i < menuConfig.length; i++) {
    const menu = menuConfig[i];
    if (menu.link === pathname) {
      return menu;
    }
    if (menu.children) {
      for (let j = 0; j < menu.children.length; j++) {
        const subMenu = menu.children[j];
        if (subMenu.link === pathname) {
          return subMenu;
        }
      }
    }
  }
  return undefined;
}

export function currentCrumb(pathname: string): string[] {
  for (let i = 0; i < menuConfig.length; i++) {
    const menu = menuConfig[i];
    if (menu.breadCrumb && menu.link === pathname) {
      return menu.breadCrumb as string[];
    }
    if (menu.children) {
      for (let j = 0; j < menu.children.length; j++) {
        const subMenu = menu.children[j];
        if (subMenu.breadCrumb && subMenu.link === pathname) {
          return subMenu.breadCrumb as string[];
        }
      }
    }
  }

  return [];
}

export function adminMenus(role: AdminRole) {
  const menus: SideMenuConfig[] = [];
  menuConfig.forEach((m) => {
    if (!m.roles) {
      return;
    }
    if (m.roles.indexOf(role) >= 0) {
      menus.push(m);
    }
  });
  return menus;
}

export default menuConfig;
