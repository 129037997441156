import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

/**
 * REF : https://usehooks.com/useRouter/
 */
export default function useRouter() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // 쿼리 객체를 문자열로 만들어줌
  const stringify = (query: any) => {
    return queryString.stringify(query, { arrayFormat: 'comma' });
  };

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search, { arrayFormat: 'comma' }), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      location,
      navigate,
      stringify,
    };
  }, [params, location]);
}
