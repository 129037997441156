export const PLAYPOT_SERVER = process.env.REACT_APP_PLAYPOT_SERVER;
export const COMMUNITY_SERVER = process.env.REACT_APP_COMMUNITY_SERVER;

/**
 * 개발용 설정
 */
const devConfigType = process.env.REACT_APP_DEV_SERVER || 'devServer';
const developConfig: { [x in string]: IServerConfig } = {
  local: {
    appUrl: 'http://localhost:3001',
    serverHost: 'http://localhost:8001',
    externalApiHost: 'https://graphql-dev.playpot.net/games-api',
    useLang: true,
  },
  devServer: {
    appUrl: 'http://localhost:3001',
    serverHost: 'http://localhost:8001',
    externalApiHost: 'https://graphql-dev.playpot.net/games-api',
    useLang: true,
  },
};

/**
 * 배포용 설정
 */
const productConfigType = process.env.REACT_APP_PRODUCT_CONFIG || 'product';
const productConfig: { [x in string]: IServerConfig } = {
  dev: {
    appUrl: 'https://localhost:3001',
    serverHost: 'https://graphql-dev.playpot.net',
    externalApiHost: 'https://graphql-dev.playpot.net/games-api',
    useLang: true,
  },
  test: {
    appUrl: 'https://manager-test.playpot.net',
    serverHost: 'https://graphql-test.playpot.net',
    externalApiHost: 'https://api.playpot.net/games-api',
    useLang: true,
  },
  product: {
    appUrl: 'https://manager.playpot.net',
    serverHost: 'https://api.playpot.net',
    externalApiHost: 'https://api.playpot.net/games-api',
    useLang: false,
  },
};

export const serverConfig: IServerConfig =
  process.env.NODE_ENV === 'development'
    ? developConfig[devConfigType]
    : productConfig[productConfigType];
