import { useEffect } from 'react';
import { atom, selector, useRecoilState, useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { clients } from '../Apollo';
import { ResponseResult } from '../common/response';
import USER_AUTH_RENEWAL from '../graphql/auth/userAuthRenewal.gql';
import { authRemove, authSave } from '../lib/jwtAuth';
import { localStorageLoadObject } from '../lib/localStorageUtils';

type AuthState = 'LOGIN' | 'LOGOUT';
type RenewalState = 'START' | 'DOING' | 'DONE';

export const authState = atom<AuthState>({
  key: 'auth',
  default: 'LOGIN',
});

export const renewalState = atom<RenewalState>({
  key: 'renewal',
  default: 'START',
});

export const userState = atom<IUser | undefined>({
  key: 'user',
  default: undefined,
});

export const updateUserState = selector<IUser | undefined>({
  key: 'setUserLogin',
  get: ({ get }) => {
    return get(userState);
  },
  set: ({ set }, value) => {
    authSave(value as IUser);
    set(userState, value);
  },
});

export const userStateRenewal = selector<IUser | undefined>({
  key: 'userStateRenewal',
  get: async () => {
    const setRenewalState = useSetRecoilState(renewalState);
    const setAuthState = useSetRecoilState(authState);
    const setUserState = useSetRecoilState(userState);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setRenewalState('DONE');
        return undefined;
      }
      const user = localStorageLoadObject('user');
      if (!user) {
        setRenewalState('DONE');
        return undefined;
      }
      // 임시로 인증
      authSave({ ...user, token } as IUser);
      setRenewalState('DOING');
      const res = await clients.auth.query({
        query: USER_AUTH_RENEWAL.query,
      });
      if (!res.data.userAuthRenewal || res.data.userAuthRenewal.result !== ResponseResult.SUCCESS) {
        authRemove();
        setRenewalState('DONE');
        return undefined;
        // throw new Error(res.data.userAuthRenewal.error);
      }
      authSave(res.data.userAuthRenewal.data as IUser);
      setUserState(res.data.userAuthRenewal.data);
      setAuthState('LOGIN');
      setRenewalState('DONE');
      return res.data.userAuthRenewal.data;
    } catch (error) {
      throw error;
    } finally {
      setRenewalState('DONE');
    }
  },
  set: ({ set }, values) => {
    set(userState, values);
  },
});

export const useUserUpdate = () => {
  return useRecoilState(updateUserState);
};

export const useUserRenewalState = () => {
  const [state, setState] = useRecoilStateLoadable(userStateRenewal);
  return { user: state.contents, state: state.state, setState };
};

export const useUserState = () => {
  return useRecoilState(userState);
};

export const useAuthState = () => {
  return useRecoilState(authState);
};

export const useAuthRenewal = () => {
  const [authRenewal, setAuthRenewal] = useRecoilState(userStateRenewal);
  const [, setAuth] = useRecoilState(authState);

  useEffect(() => {
    if (authRenewal) {
      setAuthRenewal(authRenewal);
      setAuth('LOGIN');
    } else {
      setAuth('LOGOUT');
    }
  }, [authRenewal]);
};
