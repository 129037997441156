import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localStorageSave } from '../lib/localStorageUtils';
import { authState, renewalState, userState } from '../recoil/admin';
import useRouter from './useRouter';

const useAuth = () => {
  const user = useRecoilValue<IUser | undefined>(userState);
  const auth = useRecoilValue(authState);
  const renewal = useRecoilValue(renewalState);
  const isLogin = auth !== 'LOGOUT';
  const navigate = useNavigate();
  const location = useRouter();

  useEffect(() => {
    if (!isLogin) {
      localStorageSave('prevPathName', window.location.pathname);
    }
  }, [isLogin, location, navigate]);

  return { isLogin, user, authState: auth, renewal };
};

export default useAuth;
