import { css } from '@emotion/css';
import { Breadcrumb, Image, Layout, Space, Typography } from 'antd';
import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideMenu from '../component/sidebar/SideMenu';
import { currentCrumb, currentMenu } from '../component/sidebar/sideMenuConfig';
import useAuth from '../hook/useAuth';

const { Title } = Typography;
const { Header, Content, Sider } = Layout;

const headerBg = css`
  background-color: #fff;
`;

const AdminLayout = () => {
  const { isLogin, renewal } = useAuth();
  const navigate = useNavigate();

  if (isLogin === false) {
    navigate('/auth/login');
    return null;
  }

  const { pathname } = useLocation();

  const title = () => currentMenu(pathname)?.title;
  const crumb = () => currentCrumb(pathname);

  if (renewal !== 'DONE') {
    return null;
  }

  return (
    <Layout>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
        }}
      >
        <div className="space-align-container" style={{ margin: '1.2em' }}>
          <Link to="/admin/dashboard">
            <div className="space-align-block">
              <Space align="center">
                <Image src="/favicon-57.png" width={40} />
                <div>
                  <Title level={3} className="mock-block" style={{ color: '#e36c12' }}>
                    PLAYPOT
                  </Title>
                  <Title level={5} style={{ color: 'white', marginTop: '-1em' }}>
                    Administor
                  </Title>
                </div>
              </Space>
            </div>
          </Link>
        </div>
        <SideMenu />
      </Sider>
      <Layout>
        <Header className={headerBg}>
          <Title>{title()}</Title>
          <Breadcrumb style={{ margin: '-1.6em 0' }}>
            {crumb().map((c: any, index) => (
              <Breadcrumb.Item key={index}>{c.breadcrumbName}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Header>
        <Content style={{ position: 'relative' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
