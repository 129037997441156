import { Menu } from 'antd';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { authRemove } from '../../lib/jwtAuth';
import { useAuthState, userState } from '../../recoil/admin';
import menuConfig from './sideMenuConfig';

const { SubMenu } = Menu;

const SideMenu = () => {
  const navigate = useNavigate();
  const [, setAuth] = useAuthState();
  const userReset = useResetRecoilState(userState);

  const logout = () => {
    setAuth('LOGOUT');
    userReset();
    authRemove();
    navigate('/auth/login');
  };

  const { pathname } = useLocation();

  return (
    <Menu
      theme="dark"
      selectedKeys={[pathname]}
      defaultOpenKeys={['/admin/competition']}
      mode="inline"
    >
      {menuConfig.map((menu) =>
        menu.type === 'menu' ? (
          <Menu.Item key={menu.link}>
            <Link to={String(menu.link)}>
              {menu.icon}
              <span>{menu.title}</span>
            </Link>
          </Menu.Item>
        ) : (
          <SubMenu
            key={menu.link}
            title={
              <span>
                {menu.icon}
                <span>{menu.title}</span>
              </span>
            }
          >
            {menu.children &&
              menu.children.map((sub) => (
                <Menu.Item key={sub.link}>
                  <Link to={String(sub.link)}>
                    {sub.icon && sub.icon}
                    <span>{sub.title}</span>
                  </Link>
                </Menu.Item>
              ))}
          </SubMenu>
        )
      )}
      <div className="ant-layout-sider-trigger" style={{ width: '200px' }} onClick={logout}>
        로그아웃
      </div>
    </Menu>
  );
};
export default SideMenu;
