import { Col, Row, Spin } from 'antd';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLayout from './layout/AdminLayout';
import CenterLayout from './layout/CenterLayout';
import RootView from './view/RootView';

const LoginView = React.lazy(() => import('./view/auth/LoginView'));
const SignUpView = React.lazy(() => import('./view/auth/SignupView'));

const DashboardView = React.lazy(() => import('./view/admin/dashboard/DashboardView'));
const WslView = React.lazy(() => import('./view/admin/wsl/WslView'));
const BannerView = React.lazy(() => import('./view/admin/banner/BannerView'));
const UserView = React.lazy(() => import('./view/admin/user/UserView'));
const TeamView = React.lazy(() => import('./view/admin/team/TeamView'));
const CompetitionListView = React.lazy(
  () => import('./view/admin/competition/CompetitionListView')
);
const CompetitionConfigView = React.lazy(
  () => import('./view/admin/competition/CompetitionConfigView')
);
const AdminView = React.lazy(() => import('./view/admin/admin/AdminView'));

const Page404 = React.lazy(() => import('./view/error/Page404'));

const AppRouter = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
            <Col>
              <Spin />
            </Col>
          </Row>
        }
      >
        <Routes>
          <Route path="/auth" element={<CenterLayout />}>
            <Route path="/login" element={<LoginView />} />
            <Route path="/signup" element={<SignUpView />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/wsl" element={<WslView />} />
            <Route path="/banner" element={<BannerView />} />
            <Route path="/user" element={<UserView />} />
            <Route path="/team" element={<TeamView />} />
            <Route path="/competition/list" element={<CompetitionListView />} />
            <Route path="/competition/config" element={<CompetitionConfigView />} />
            <Route path="/admin" element={<AdminView />} />
          </Route>
          <Route path="/" element={<RootView />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
