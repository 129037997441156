export const localStorageSave = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const localStorageSaveObject = (key: string, value: Object) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageLoad = (key: string): string => {
  const data = window.localStorage.getItem(key);
  if (!data) {
    return '';
  }
  return data;
};

export const localStorageLoadObject = (key: string): Object | null => {
  const data = window.localStorage.getItem(key);
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};
