// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { useAuthRenewal } from './recoil/admin';

const AuthRenewal = (): React.ReactElement | null => {
  useAuthRenewal();
  return null;
};

export default AuthRenewal;
