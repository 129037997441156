import gql from 'graphql-tag';

/**
 * 회원 인증 정보 갱신
 */
const query = gql`
  query {
    userAuthRenewal {
      result
      data {
        id
        name
        email
        imageUrl
        bp
        level
        role
        tag
        teamMember
        mainGame
        team {
          id
          tag
        }
        token
        competitionInformation
      }
      text
      message
      dateTime
      error
    }
  }
`;

const USER_AUTH_RENEWAL: IGraphqlQuery = {
  client: 'auth',
  mode: 'query',
  names: ['userAuthRenewal'],
  query,
};

export default USER_AUTH_RENEWAL;
