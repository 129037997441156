import React from 'react';
import ReactDOM from 'react-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import App from './App';
import 'antd/dist/antd.css';

ReactDOM.render(
  <React.Suspense fallback={<TopBarProgress />}>
    <App />
  </React.Suspense>,
  document.getElementById('root')
);
